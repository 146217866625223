<script setup>
import AppLayout from "@/components/AppLayout.vue";
import {ref} from "vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";
import emitter from "@/utils/eventBus";
const onchage = () => {
  emitter.emit("onlineClient")
}
const closeIframe = () => {
  showframe.value = false
}
const info = [
  {
    title: '外观定制',
    text: '为用户量身定制，全方位满足，多年经验，专属外观，提升品牌形象。',
    tag : ['机身logo','包材','标签铭牌','说明书&保修卡&合格证','标准桌面机箱','特殊形态','服务器机架机箱'],
  },
  {
    title: '功能定制',
    text: '满足个性化需求，提高使用体验，让企业用根据自己需求选择产品功能。',
    tag : ['开机logo','BIOS功能','定制系统','预装软件'],
  },
  {
    title: '服务定制',
    text: '全方位电脑组装服务，一站式便捷购物，安心使用，开心办公。',
    tag : ['门到桌部署','额外延保','定期驻点','定期巡检','桌面运维'],
    icon: './image/serve.png'
  },]
</script>

<template>
  <AppLayout>
    <div class="schemePage">
      <div class="schemePageBanner"></div>
      <div style=" margin: 0 auto;margin-top: 60px !important;color: #FFFFFF">
        <div class="schemeContainer container1" @click="onchage()">
          <div class="title1">
            <img style="width: 32px;height: 34px;" src="./image/computer.png" alt="">
            <div class="title">{{info[0].title}}</div>
          </div>
          <div class="text">
            {{info[0].text}}
          </div>
          <div class="tagList">
            <div v-for="item in info[0].tag" class="tag">
              {{item}}
            </div>
          </div>
          <div class="button">
            <span style="width: 71px;">立即咨询</span>
            <img class="img_right" src="./image/right_hover.png" alt="">
            <img class="img_right_hover" src="./image/right.png" alt="">
          </div>
          <div class="explain">专业咨询顾问为您量身定制</div>
        </div>
        <div class="schemeContainer container2" @click="onchage()">
          <div class="title1">
            <img style="width: 34px;height: 32px;" src="./image/function.png" alt="">
            <div class="title">{{info[1].title}}</div>
          </div>
          <div class="text">
            {{info[1].text}}
          </div>
          <div class="tagList">
            <div v-for="item in info[1].tag" class="tag">
              {{item}}
            </div>
          </div>
          <div style="margin-top: 94px !important;" class="button">
            <span style="width: 71px;">立即咨询</span>
            <img class="img_right" src="./image/right_hover.png" alt="">
            <img class="img_right_hover" src="./image/right.png" alt="">
          </div>
          <div class="explain">专业咨询顾问为您量身定制</div>
        </div>
        <div class="schemeContainer container3" @click="onchage()">
          <div class="title1">
            <img style="width: 33px;height: 32px;" src="./image/serve.png" alt="">
            <div class="title">{{info[2].title}}</div>
          </div>
          <div class="text">
            {{info[2].text}}
          </div>
          <div class="tagList">
            <div v-for="item in info[2].tag" class="tag">
              {{item}}
            </div>
          </div>
          <div class="button">
            <span style="width: 71px;">立即咨询</span>
            <img class="img_right" src="./image/right_hover.png" alt="">
            <img class="img_right_hover" src="./image/right.png" alt="">
          </div>
          <div class="explain">专业咨询顾问为您量身定制</div>
        </div>
      </div>
      <!--<iframe class="openIframe" src="https://cschat.antcloud.com.cn/index.htm?tntInstId=nGx_rHip&scene=SCE01268584" width="23%" height="65%"  v-show="showframe">
      </iframe>
      <div class="closeIframe" @click="closeIframe" v-show="showframe">
        <img src="./image/close.png" alt="">
      </div>-->
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<style scoped lang="less">
.schemePage {
  width: 1920px;
  margin: 0 auto;
  padding-bottom: 36px;
  background-color: #fff;
  .schemePageBanner {
    height: 600px;
    background-image: url("image/shemeBanner.png");
    background-size: cover;
  }
  .schemeContainer {
    height: 401px;
    width: 1200px;
    margin-bottom: 23px !important;
    margin: 0 auto;
    padding-left: 67px;
    background-size: cover;
    cursor:pointer;
    position: relative;
    .hoverShadow (); // 鼠标移入动画
    .title1{
      display: flex;
      width: 180px;
      justify-content: space-between;
      align-items: center;
      padding-top: 63px;
      .title {
        width: 130px;
        height: 45px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 32px;
      }
    }
    .text {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 15px;
      margin-top: 24px;
    }
    .tagList{
      width: 460px;
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      .tag{
        height: 28px;
        line-height: 26px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 13px;
        border-radius: 5px;
        border: 1px solid #C1C1C1;
        padding: 0 14px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    &:hover .button{
      color: #ce1200;
    }
    &:hover .button>.img_right{
      position: absolute;
      opacity: 0;
    }
    &:hover .button>.img_right_hover{
      position: relative;
      opacity: 1;
    }
    .button {
      //width: 110px;
      height: 18px;
      line-height: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      margin-top: 56px;
      .img_right{
        width: 6px;
        height: 10px;
        line-height: 10px;
        margin-left: 6px;
        transform: translateY(-15%);
      }
      .img_right_hover{
        width: 6px;
        height: 10px;
        position: absolute;
        margin-left: 6px;
        opacity: 0;
        transform: translateY(-15%);
      }
    }
    .explain {
      //width: 144px;
      height: 13px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      margin-top: 12px;
    }
  }
  .container1 {
    background-image: url("./image/scheme1_1.png");
  }
  .container2 {
    background-image: url("./image/scheme2_1.png");
  }
  .container3 {
    background-image: url("./image/scheme3_1.png");
  }
  .openIframe{
    position: fixed;
    right: 6.5rem;
    bottom: 2rem;
    border: none;
    box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.2);
    border-radius: 20px;
    z-index: 999;
  }
  .closeIframe{
    cursor: pointer;
    position: fixed;
    right: 8rem;
    bottom: 64%;
    z-index: 999;
  }
}
</style>
